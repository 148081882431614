import { t } from "ttag";

import EditableText, {
  type EditableTextProps,
} from "metabase/core/components/EditableText";
import { Box, type BoxProps } from "metabase/ui";

export interface EditableCacheProps extends BoxProps, EditableTextProps {
  cacheTTL: number | null;
  canWrite: boolean;
  onChange: (newCacheTTL: number) => void;
  key?: string | number;
}

export const EditableCache = ({
    cacheTTL,
    canWrite,
    onChange,
    key,
    ...props
}: EditableCacheProps) => {
  return (
    <Box
      component={EditableText}
      onChange={onChange}
      initialValue={cacheTTL}
      placeholder={
        !cacheTTL && !canWrite ? t`No cache_ttl` : t`Add cache_ttl`
      }
      isDisabled={!canWrite}
      isOptional
      isMultiline
      isMarkdown
      key={key}
      lh={1.57}
      {...props}
    />
  );
};
