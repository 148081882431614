import { t } from "ttag";

import { ToolbarButton } from "metabase/components/ToolbarButton";
import { useDispatch } from "metabase/lib/redux";
import {    
    fetchDashboardCardData,
  } from "metabase/dashboard/actions";  
import { clearCache } from "mustache";

export const DashboardReloadButton = ({
  item,
}) => {
    const dispatch = useDispatch();
    const reload = async () => {
        // optimistically dismissing all the undos before the saving has finished
        // clicking on them wouldn't do anything at this moment anyway    
        await dispatch(fetchDashboardCardData({isRefreshing: true,
          reload: true,
          clearCache: true,
          ignoreCache: true,
        }));    
    };

  return (
    <ToolbarButton
      tooltipLabel={t`Reload from cache`}
      visibleOnSmallScreen={false}
      key="reload"
      aria-label={t`Reload dashboard`}
      icon="refresh"
      onClick={reload}
    />
  );
};
